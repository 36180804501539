import { createRouter, createWebHistory } from 'vue-router'
import { loadLayoutMiddleware } from "@/router/middleware/loadLayoutMiddleware";

import NotFound from '../views/NotFound.vue'
import HelloWorld from '../components/HelloWorld.vue'
import Index from '../views/Index.vue'
import WebRequestView from '../views/WebRequest.vue'
import UsersIndexView from '../views/UsersIndexView.vue'
import TableServerAsync from '../views/TableServerAsync.vue'
import LoginView from '../views/User/UnauthenticatedView.vue'
import LoginComponent from '../components/User/Login.vue'
//import RegistrationView from '../views/User/RegistrationView.vue'
import RegistrationComponent from '../components/User/Registration.vue'
//import ForgotPasswordView from '../views/User/PasswordForgotView.vue'
import ForgotPasswordComponent from '../components/User/PasswordForgot.vue'
//import PasswordResetView from '../views/User/PasswordResetView.vue'
import PasswordResetComponent from '../components/User/PasswordForgot.vue'
import UserProfileView from '../views/User/UserProfileView.vue'
import DashboardView from '../views/DashboardView.vue'


const routes = [

  {
    path: '/', name: 'root', component: Index, redirect: { name: 'dashboard' }, meta: { layout: 'default' },
    children: [
      { path: '/hello', component: HelloWorld, meta: { layout: 'fullpage' } },
      { path: '/web', name: 'web', component: WebRequestView },
      { path: '/dashboard', name: 'dashboard', component: DashboardView },
      { path: '/users', name: 'users', component: UsersIndexView },
      { path: '/user/profile', name: 'profile', component: UserProfileView },
      { path: '/table/async', name: 'table-async', component: TableServerAsync },
    ]
  },

  {
    path: '/login', name: 'unauthenticated', component: LoginView, meta: { layout: 'fullpage' },
    children: [
      { path: '/login', name: 'login', component: LoginComponent },
      { path: '/signup', name: 'signup', component: RegistrationComponent },
      { path: '/user/forgot-password', name: 'forgot-password', component: ForgotPasswordComponent, },
      { path: '/user/reset-password/:token?', name: 'reset-password', component: PasswordResetComponent, },
    ]
  },

  { path: '/:pathMatch(.*)*', name: '404', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

const defaultTitle = 'FakeCRM';
router.beforeEach((to, from, next) => {

  // set name
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = `${nearestWithTitle.meta.title}`;
  } else document.title = defaultTitle;

  //const keepAlive = !!to.meta.keepAlive;
  //const toDepth = to.fullPath.split('/').filter(n => n).length;
  //const fromDepth = from.fullPath.split('/').filter(n => n).length;
  //const newTransition = toDepth < fromDepth ? 'slide-left' : 'slide-right';
  //to.meta.transition = newTransition;
  //console.log('navigating', from.fullPath, 'to', to.fullPath, 'transition', newTransition);

  // requires authorization - che in realtà è la autenticazione
  if (to.meta.authorize) {
    // TODO: messaggio di errore
    next({ name: 'login' });
  } else {
    next();
  }

});

export default router
